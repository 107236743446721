.news-ticker {

  .news-ticker-inner {
    background-color: rgba(0,35,70, 0.7);
    padding: 3px 0;
    margin-top: 16px;
    z-index: 100;
  }

  .container {
    position: relative;
  }

  .text {
    position: absolute;
    left: 41px;
    font-size: 1.2em;
    letter-spacing: 0.1em;
    bottom: -2px;
  }

  .ticker {
    padding-left: 103px;
    padding-right: 26px;
  }

  .seperator {
    letter-spacing: 0.2em;
    padding: 0 15px;
  }

  a {
    .transition
  }

  a:hover, a:focus {
    color: @color-bg-banner;
  }

  ul#webticker {
    //important to enable css3 transitions properly
    -webkit-transition: all 0s linear;
    -moz-transition: all 0s linear;
    -o-transition: all 0s linear;
    transition: all 0s linear;
    list-style:none;
    margin:0;
  }

}