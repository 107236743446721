@duration: 10s;

.blink {
  width: 50px;
  height: 50px;
  position: absolute;
  z-index: 1;


  .layer1 {
    position: absolute;
    border-radius: 50%;
    animation: blink-1 @duration infinite;
    animation-delay: 0s;
    transition: all 0.4s;
    background: white;
    left: 50%;
    top: 50%;
    margin-left: -4px;
    margin-top: -4px;
    width: 8px;
    height: 8px;
    opacity: 0;
  }

  .layer2 {
    position: absolute;
    border-radius: 50%;
    animation: blink-last @duration infinite;
    animation-delay: 0.1s;
    transition: all 0.4s;
    left: 50%;
    top: 50%;
    width: 1%;
    height: 1%;
    margin: -0.5% auto auto -0.5%;
    border: 0 solid white;
  }

  .layer3 {
    position: absolute;
    border-radius: 50%;
    animation: blink-last @duration infinite;
    animation-delay: 0.5s;
    transition: all 0.4s;
    left: 50%;
    top: 50%;
    width: 1%;
    height: 1%;
    margin: -0.5% auto auto -0.5%;
    border: 0 solid white;
  }

  .layer4 {
    position: absolute;
    border-radius: 50%;
    animation: blink-last @duration infinite;
    animation-delay: 0.8s;
    transition: all 0.4s;
    left: 50%;
    top: 50%;
    width: 1%;
    height: 1%;
    margin: -0.5% auto auto -0.5%;
    border: 0 solid white;
  }

  @-webkit-keyframes blink-last {
    0% {
      opacity: 1;
      z-index: 0;
      width: 1%;
      margin-left: -0.5%;
      height: 1%;
      margin-top: -0.5%;
      border: 2px solid white;
    }
    10% {
      opacity: 0;
    }
    20% {
      opacity: 0;
      width: 100%;
      margin-left: -50%;
      height: 100%;
      margin-top: -50%;
    }
    100% {
      opacity: 0;
      z-index: 0;
      width: 100%;
      margin-left: -50%;
      height: 100%;
      margin-top: -50%;
    }
  }

  @-moz-keyframes blink-last {
    0% {
      opacity: 1;
      z-index: 0;
      width: 1%;
      margin-left: -0.5%;
      height: 1%;
      margin-top: -0.5%;
      border: 2px solid white;
    }
    10% {
      opacity: 0;
    }
    20% {
      opacity: 0;
      width: 100%;
      margin-left: -50%;
      height: 100%;
      margin-top: -50%;
    }
    100% {
      opacity: 0;
      z-index: 0;
      width: 100%;
      margin-left: -50%;
      height: 100%;
      margin-top: -50%;
    }
  }

  @-o-keyframes blink-last {
    0% {
      opacity: 1;
      z-index: 0;
      width: 1%;
      margin-left: -0.5%;
      height: 1%;
      margin-top: -0.5%;
      border: 2px solid white;
    }
    10% {
      opacity: 0;
    }
    20% {
      opacity: 0;
      width: 100%;
      margin-left: -50%;
      height: 100%;
      margin-top: -50%;
    }
    100% {
      opacity: 0;
      z-index: 0;
      width: 100%;
      margin-left: -50%;
      height: 100%;
      margin-top: -50%;
    }
  }

  @keyframes blink-last {
    0% {
      opacity: 1;
      z-index: 0;
      width: 1%;
      margin-left: -0.5%;
      height: 1%;
      margin-top: -0.5%;
      border: 2px solid white;
    }
    10% {
      opacity: 0;
    }
    20% {
      opacity: 0;
      width: 100%;
      margin-left: -50%;
      height: 100%;
      margin-top: -50%;
    }
    100% {
      opacity: 0;
      z-index: 0;
      width: 100%;
      margin-left: -50%;
      height: 100%;
      margin-top: -50%;
    }
  }

  @keyframes blink-1 {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    20% {
      opacity: 0;
    }
    100% {
      width: 8px;
      height: 8px;
      opacity: 0;
    }
  }
}

.blink-1 {
  left: 58%;
  margin-top: 37%;
}

.blink-2 {
  left: 53%;
  margin-top: 33%;

  .layer1 {
    animation-delay: 1s;
  }
  .layer2 {
    animation-delay: 1s;
  }
  .layer3 {
    animation-delay: 1.4s;
  }
  .layer4 {
    animation-delay: 1.7s;
  }
}

.blink-3 {
  left: 71%;
  margin-top: 7%;

  .layer1 {
    animation-delay: 3s;
  }
  .layer2 {
    animation-delay: 3s;
  }
  .layer3 {
    animation-delay: 3.4s;
  }
  .layer4 {
    animation-delay: 3.7s;
  }
}

.blink-4 {
  left: 66.5%;
  margin-top: 8%;

  .layer1 {
    animation-delay: 7s;
  }
  .layer2 {
    animation-delay: 7s;
  }
  .layer3 {
    animation-delay: 7.4s;
  }
  .layer4 {
    animation-delay: 7.7s;
  }
}

.blink-5 {
  left: 76.5%;
  margin-top: 12%;

  .layer1 {
    animation-delay: 4s;
  }
  .layer2 {
    animation-delay: 4s;
  }
  .layer3 {
    animation-delay: 4.4s;
  }
  .layer4 {
    animation-delay: 4.7s;
  }
}

.blink-6 {
  left: 31.5%;
  margin-top: 19%;

  .layer1 {
    animation-delay: 9.3s;
  }
  .layer2 {
    animation-delay: 9.3s;
  }
  .layer3 {
    animation-delay: 9.7s;
  }
  .layer4 {
    animation-delay: 10s;
  }
}