.search-results {
  padding: 45px 0;

  .clearfix-2 {
    clear: both;
  }

  .practice-result, .job-result, .device-result {
    position: relative;
    margin-bottom: 24px;
    overflow: visible;
    &.inactive {
      > div {
        opacity: .4;
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
      }
    }
  }

  .practice-description, .job-description, .device-description {
    font-size: 1.06rem;
    letter-spacing: 0.13rem;

    .title {
      text-transform: uppercase;
      margin-top: 3px;
      margin-bottom: 16px;
      font-weight: normal;
      font-size: 2.1rem;
      letter-spacing: 0.21rem;
    }

    .description-text {
      margin-bottom: 2px;
      letter-spacing: 0.06rem;
    }

    .job-description-text, .device-description-text {
      font-size: 1.2rem;
      letter-spacing: 0.034rem;
    }

    .description-list {
      list-style: none;

      li {
        display: block;
        position: relative;
        padding-left: 21px;
        font-size: 1.2rem;
        letter-spacing: 0.034rem;
        line-height: 1.6rem;
        margin-bottom: 8px;

        .zip-code {
          display: inline;
          &:after {
            content:"\a";
            white-space: pre;
          }
        }

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          display: inline-block;
          width: 12px;
          height: 16px;
        }

        &.location {
          &:before {
            background: url("../Svg/Ort.svg") center center no-repeat;
            background-size: 11px;
          }
        }
        &.subject, &.category {
          &:before {
            background: url("../Svg/Starttermin.svg") center center no-repeat;
            background-size: 11px;
          }
        }
        &.practical-form {
          &:before {
            background: url("../Svg/Praxisform.svg") center center no-repeat;
            background-size: 11px;
          }
        }
        &.date {
          &:before {
            background: url("../Svg/Kalender.svg") center center no-repeat;
            background-size: 11px;
          }
        }
        &.manufacturer {
          &:before {
            background: url("../Svg/Hersteller.svg") center center no-repeat;
            background-size: 11px;
          }
        }
        &.type {
          &:before {
            background: url("../Svg/Typ.svg") center center no-repeat;
            background-size: 11px;
          }
        }
        &.price {
          &:before {
            background: url("../Svg/Euro.svg") top 3px left no-repeat;
            background-size: 8px;
          }
        }
      }
    }

    a.ws-link {
      margin-top: 13px;
      font-size: 1.1rem;
    }
  }
}

.admin-set-active, .admin-edit {
  background-color: @color-bg-quote-box;
  width: 25px;
  height: 25px;
  border-radius: 13px;
  display: block;
  position: absolute;
  left: 30px;
  z-index: 10;
  text-align: center;
  line-height: 26px;
  top: 15px;
  -webkit-filter: grayscale(0);
  filter: grayscale(0);

  transition: @transition-standard;

  &:hover {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}

.admin-edit{
  left: 60px;
}

.search-results-block {
  &:nth-child(even) {
    background: @color-bg-panel-open;
    color: @color-font-alt;
  }
  h3.section-title {
    font-size: 1.55em;
    font-weight: bold;
    letter-spacing: .125em;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  h3 {
    margin-top: 0;
  }
}
