.success-slider {
  background: @color-bg-success-slider;
  color: @color-font-alt;
  position: relative;

  .carousel {
    position: inherit;
    padding: 0;

    .carousel-indicators {
      top: 37px;
      bottom: inherit;
      li {
        border: none;
        border-radius: 0;
        width: 40px;
        height: 40px;
        margin: 0;
        text-indent: 0;
        color: @color-font;
        font-size: 1.7rem;
        line-height: 4.2rem;
        letter-spacing: 0.1rem;
        margin-right: 36px;
        transition: @transition-standard;
        &.active {
          background: @color-bg-carousel-indicator;
        }
        &:not(.active):hover {
          background: @color-bg-carousel-indicator-hover;
        }
      }
    }

    .carousel-control {
      background: none;
      position: absolute;
      opacity: 1;
      width: 5%;
      &.left {
        background: url("../Svg/Pfeil_gross_links.svg") center center no-repeat;
        background-size: 40px;
        left: 0;
      }
      &.right {
        background: url("../Svg/Pfeil_gross_rechts.svg") center center no-repeat;
        background-size: 40px;
        right: 0;
      }
    }

    .carousel-inner {

      .item.left, .item.right {
        .background-number {
          opacity: 0;
          line-height: 1px;
        }

        .content-inner {
          .content-left {
            margin-left: -200px;
            //opacity: 0;
          }

          .content-right {
            margin-left: 250px;
            //opacity: 0;
          }
        }
      }

      .carousel-content {

        .background-number {
          color: @color-font-number-slider-bg;
          font-size: 78rem;
          position: absolute;
          //z-index: -1; // works in Chrome, doesn't work in Firefox
          line-height: 38rem;
          margin-left: 5px;
          font-weight: bold;
          opacity: 1;
          //transition: @transition-standard;
          will-change: line-height;
        }

        .content-inner {
          padding-top: 151px;
          padding-bottom: 135px;

          .content-left {
            opacity: 1;
            margin-left: -4px;
            transition: @transition-standard;
            h2 {
              margin: 0;
              color: @color-font-headline-alt;
              font-size: 3.3rem;
              letter-spacing: 0.4rem;
              font-weight: bold;
            }
          }

          .content-right {
            opacity: 1;
            margin-left: 0;
            transition: @transition-standard;
            h3 {
              font-size: 2.1rem;
              letter-spacing: 0.21rem;
              margin-top: 2px;
              margin-bottom: 15px;
            }

            p {
              font-size: 1.183rem;
              line-height: 1.6rem;
              margin-bottom: 0;
            }
            ul {
              padding-left: 18px;
              margin: 15px 0;
              li {
                font-size: 1.183rem;
                line-height: 1.6rem;
              }
              &.ul-custom {
                list-style: none;
              }
            }
          }

        }

      }


    }

  }
}