.page-summary {
  padding-bottom: 73px;

  h1 {
    padding-left: 20px;
    font-size: 5.4em;
    margin-bottom: 27px;
    padding-top: 5px;
  }

  h2 {
    letter-spacing: 0.04em;
  }

  p {
    width: 70%;
  }
}