.events-list {
  padding-top: 58px;
  padding-bottom: 48px;
  .event-content {
    padding-bottom: 44px;
    &:last-child {
      padding-bottom: 0;
    }
    h3 {
      text-transform: uppercase;
      font-size: 2rem;
      line-height: 2.5rem;
      letter-spacing: 0.27rem;
      margin-top: 0;
      margin-bottom: 7px;
    }
    p {
      font-size: 1.184rem;
      margin: 0;
      &.event-date {
        font-size: 1.22rem;
        &:before {
          content: "";
          display: inline-block;
          background: url("../Svg/Kalender.svg") bottom center no-repeat;
          background-size: 12px;
          width: 12px;
          height: 13px;
          margin-right: 6px;
          margin-bottom: -1px;
        }
      }
    }
    a.event-details {
      margin-top: 11px;
    }
  }
}