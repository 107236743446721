html {
  height: 100%;
}
body {
  background-color: @color-bg-panel;
  color: @color-font;
  font-family: @font-family;
  font-variant: normal;
  font-weight: 500;
  font-size: @base-font-size;
  overflow-y: scroll;
  width: 100%;
  min-height: 100%;
  padding-bottom: 37px;
  position: relative;
  &.searchresults {
    background: @color-bg-panel-open;
    .search-icon {
      background-color: @color-bg-panel;
      .default-section {
        padding-top: 158px; //workaround for removing white background behind header
        margin-top: -100px;
      }
    }
  }
}

ul {
  padding: 0;
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {

}

h1.title {
  font-family: @font-family-headline;
  font-size: 7em;
}

a {
  color: @color-font;
  &:hover, &:focus {
    color: @color-font;
    text-decoration: none;
    outline: none;
  }
}

p {
  font-size: 1.1em;
  line-height: 24px;
  letter-spacing: 0.4px;
}

button {
  &:focus {
    outline: none;
  }
}

.vertical-line {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  border-left: 1px solid @color-horizontal-line;
}

.left-50p {
  left: 50%;
}

.position-relative {
  position: relative;
}

.startpage-layout > section:first-child,
body {
  padding-top: 140px;

  &.startpage {
    padding-top: 0;
  }
}

.overview-layout {
  overflow: hidden;
}

.transition {
  transition: @transition-standard;
}

.margin-auto {
  margin: auto;
}

.default-section {
  padding: 58px 0 27px;
}

a.link-default {
  text-transform: uppercase;
  letter-spacing: 0.29rem;
  font-weight: bold;
  font-size: 0.9em;
  display: inline-block;
  position: relative;
  transition: @transition-standard;

  &:hover, &:focus {
    opacity: 0.6;
  }
}

.ws-link {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0 7px;
  cursor: pointer;
  background: @color-bg-button;
  text-decoration: none;
  color: @color-font-button;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: .22rem;
  font-size: 0.9em;
  line-height: 2.4rem;
  -webkit-tap-highlight-color: rgba(0,0,0,0);

  // top and bottom

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 100%;
    right: 0;
    top: 0;
    background: @color-button-border-hover;
    height: @button-border-width;
    -webkit-transition-property: left;
    transition-property: left;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }

  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    right: 100%;
    left: 0;
    bottom: 0;
    background: @color-button-border-hover;
    height: @button-border-width;
    -webkit-transition-property: right;
    transition-property: right;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }

  &.light-bg {

    &:hover {
      overflow: visible;
      &:before {
        border-top: 1px solid @color-bg-button;
        height: 2px;
        top: -1px;
        left: -1px;
        right: -1px;
      }
      &:after {
        border-bottom: 1px solid @color-bg-button;
        height: 2px;
        bottom: -1px;
      }
      .ws-link-inner {
        &:before {
          border-left: 1px solid @color-bg-button;
          width: 2px;
          left: -1px;
          bottom: -1px;
        }
        &:after {
          border-right: 1px solid @color-bg-button;
          width: 2px;
          right: -1px;
          bottom: -1px;
        }
      }
    }
  }

  .ws-link-inner {

    // left and right

    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      left: 0;
      top: 0;
      bottom: 100%;
      background: @color-button-border-hover;
      width: @button-border-width;
      -webkit-transition-property: bottom;
      transition-property: bottom;
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
    }

    &:after {
      content: "";
      position: absolute;
      z-index: -1;
      right: 0;
      bottom: 0;
      top: 100%;
      background: @color-button-border-hover;
      width: @button-border-width;
      -webkit-transition-property: top;
      transition-property: top;
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
    }

  }

  &:hover, &:focus, &:active {
    color: @color-font;
    text-decoration: none;
    outline: none;
    &:before, &:after {
      left: 0;
      right: 0;
    }
    .ws-link-inner {
      &:before, &:after {
        bottom: 0;
        top: 0;
      }
    }
  }
}

.float-none {
  float: none;
}

.relative {
  position: relative;
}

.ajax-loader {
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.3);
  position: absolute;
  top: 0;
  left: 0;
  font-size: 2em;
  text-align: center;
  i {
    position: relative;
    top: ~"calc(50% - 2em / 4)";
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
  }
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes pulsate /* Safari and Chrome */ {
  0%,6%,20%,100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  2% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
  4% {
    -webkit-transform: translateX(1.05);
    transform: scale(1.05);
  }
}
@keyframes pulsate {
  0%,6%,20%,100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  2% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
  4% {
    -webkit-transform: translateX(1.05);
    transform: scale(1.05);
  }
}

.technical-warning-container {
  color: @color-font-error;
  font-weight: bold;

  noscript, .browserupgrade {
    &:before {
      content: "\e101";
      font-family: "Glyphicons Halflings";
      padding: 3px;
    }
  }
}

.page-loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.5);
  z-index: 10000;
  font-size: 2em;
  text-align: center;
  display: block;
  .uil-default-css {
    left: ~"calc(50% - 100px)";
    top: ~"calc(50% - 100px)";
  }
}

@-webkit-keyframes uil-default-anim {
  0% { opacity: 1}
  100% {opacity: 0}
}

@keyframes uil-default-anim {
  0% { opacity: 1}
  100% {opacity: 0}
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;

  &.dark-bg {
    .loader-bar {
      background: @color-loader-dark-bg;
    }
  }

  &.light-bg {
    .loader-bar {
      background: @color-loader-light-bg;
    }
  }

  > .loader-bar {
    top: 80px;
    left: 93px;
    width: 10px;
    height: 30px;
    position: absolute;

    -webkit-animation: uil-default-anim 1s linear infinite;
    animation: uil-default-anim 1s linear infinite;

    &:nth-of-type(1) {
      -webkit-transform:rotate(0deg) translate(0,-60px);
      transform:rotate(0deg) translate(0,-60px);
      -webkit-animation-delay: -0.5s;
      animation-delay: -0.5s;
    }

    &:nth-of-type(2){
      -webkit-transform:rotate(30deg) translate(0,-60px);
      transform:rotate(30deg) translate(0,-60px);
      -webkit-animation-delay: -0.4166666666666667s;
      animation-delay: -0.4166666666666667s;
    }

    &:nth-of-type(3){
      -webkit-transform:rotate(60deg) translate(0,-60px);
      transform:rotate(60deg) translate(0,-60px);
      -webkit-animation-delay: -0.33333333333333337s;
      animation-delay: -0.33333333333333337s;
    }

    &:nth-of-type(4){
      -webkit-transform:rotate(90deg) translate(0,-60px);
      transform:rotate(90deg) translate(0,-60px);
      -webkit-animation-delay: -0.25s;
      animation-delay: -0.25s;
    }

    &:nth-of-type(5){
      -webkit-transform:rotate(120deg) translate(0,-60px);
      transform:rotate(120deg) translate(0,-60px);
      -webkit-animation-delay: -0.16666666666666669s;
      animation-delay: -0.16666666666666669s;
    }

    &:nth-of-type(6){
      -webkit-transform:rotate(150deg) translate(0,-60px);
      transform:rotate(150deg) translate(0,-60px);
      -webkit-animation-delay: -0.08333333333333331s;
      animation-delay: -0.08333333333333331s;
    }

    &:nth-of-type(7){
      -webkit-transform:rotate(180deg) translate(0,-60px);
      transform:rotate(180deg) translate(0,-60px);
      -webkit-animation-delay: 0s;
      animation-delay: 0s;
    }

    &:nth-of-type(8){
      -webkit-transform:rotate(210deg) translate(0,-60px);
      transform:rotate(210deg) translate(0,-60px);
      -webkit-animation-delay: 0.08333333333333337s;
      animation-delay: 0.08333333333333337s;
    }

    &:nth-of-type(9){
      -webkit-transform:rotate(240deg) translate(0,-60px);
      transform:rotate(240deg) translate(0,-60px);
      -webkit-animation-delay: 0.16666666666666663s;
      animation-delay: 0.16666666666666663s;
    }

    &:nth-of-type(10){
      -webkit-transform:rotate(270deg) translate(0,-60px);
      transform:rotate(270deg) translate(0,-60px);
      -webkit-animation-delay: 0.25s;
      animation-delay: 0.25s;
    }

    &:nth-of-type(11){
      -webkit-transform:rotate(300deg) translate(0,-60px);
      transform:rotate(300deg) translate(0,-60px);
      -webkit-animation-delay: 0.33333333333333337s;
      animation-delay: 0.33333333333333337s;
    }

    &:nth-of-type(12){
      -webkit-transform:rotate(330deg) translate(0,-60px);
      transform:rotate(330deg) translate(0,-60px);
      -webkit-animation-delay: 0.41666666666666663s;
      animation-delay: 0.41666666666666663s;
    }
  }
}

.no-padding {
  padding: 0;
}

section.light-bg {
  background: @color-bg-panel-open;
  color: @color-font-alt;
}

hr {
  margin-top: 37px;
  margin-bottom: 37px;

  &.dark-hr {
    border-top: 1px solid @color-horizontal-line;
  }
}

.inline-block {
  display: inline-block;
}

.text-cols {
  -webkit-column-count: 2;
  -webkit-column-gap: 30px;
  -moz-column-count: 2;
  -moz-column-gap: 30px;
  column-count: 2;
  column-gap: 30px;
}

.pull-bottom {
  position: absolute;
  bottom: 0;
}

.display-flex {
  display: flex;
}

.contact-description {
  padding-top: 40px;
  padding-bottom: 48px;

  h4 {
    text-transform: uppercase;
    font-size: 2.1rem;
    letter-spacing: 0.21rem;
    line-height: 2.5rem;
    margin-bottom: 23px;
  }
  p {
    font-size: 1.19rem;
    line-height: 1.6rem;
    margin-bottom: 0;
  }

  img {
    width: 40px;
    margin-right: 15px;
  }
}

#hamburger {
  position: fixed;
  left: 20px;
  top: 15px;
  width: 36px;
  height: 35px;
  font: inherit;
  display: inline-block;
  overflow: visible;
  margin: 0;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: .15s;
  transition-property: opacity,-webkit-filter;
  transition-property: opacity,filter;
  transition-property: opacity,filter,-webkit-filter;
  text-transform: none;
  color: inherit;
  border: 0;
  background-color: #0072BC;
  z-index: 9999;
  padding: 5px;

  .hamburger-inner, .hamburger-inner:before, .hamburger-inner:after {
    position: absolute;
    width: 26px;
    height: 3px;
    transition-timing-function: ease;
    transition-duration: .15s;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform,-webkit-transform;
    border-radius: 0;
    background-color: @color-font;
    //outline: 1px solid @color-bg-panel;
  }

  .hamburger-inner {
    top: 50%;
    display: block;
    margin-top: -2px;

    &:before, &:after {
      content: "";
      display: block;
    }

    &:before {
      top: -9px;
      transition: top 0.1s .14s ease,opacity 0.1s ease;
    }

    &:after {
      bottom: -9px;
      transition: bottom .1s .14s ease,-webkit-transform .1s cubic-bezier(.55,.055,.675,.19);
      transition: bottom .1s .14s ease,transform .1s cubic-bezier(.55,.055,.675,.19);
      transition: bottom .1s .14s ease,transform .1s cubic-bezier(.55,.055,.675,.19),-webkit-transform .1s cubic-bezier(.55,.055,.675,.19);
    }

  }



  &.active {
    .hamburger-inner {
      transition-delay: .14s;
      transition-timing-function: cubic-bezier(.215,.61,.355,1);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);

      &:before {
        top: 0;
        transition: top .1s ease,opacity .1s .14s ease;
        opacity: 0;
        transition: top .1s .14s ease,opacity .1s ease;
      }

      &:after {
        bottom: 0;
        transition: bottom .1s ease,-webkit-transform .1s .14s cubic-bezier(.215,.61,.355,1);
        transition: bottom .1s ease, transform .1s .14s cubic-bezier(.215,.61,.355,1);
        transition: bottom .1s ease,transform .1s .14s cubic-bezier(.215,.61,.355,1),-webkit-transform .1s .14s cubic-bezier(.215,.61,.355,1);
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
      }
    }
  }
}

.ws-img-responsive {
  .img-responsive;

  width: 100%;
  max-width: 100%;
}

main {
  transition: @transition-standard;
  &.blur {
    -webkit-filter: blur(3px);
    filter: blur(3px);
  }
}

@-webkit-keyframes shake {
  0%,20%,100% {
    -webkit-transform: translateY(0);
  }
  2%,6%,10%,14%,18% {
    -webkit-transform: translateY(-5px);
  }
  4%,8%,12%,16% {
    -webkit-transform:translateY(5px)
  }
}

@-moz-keyframes shake {
  0%,20%,100% {
    -webkit-transform: translateY(0);
  }
  2%,6%,10%,14%,18% {
    -webkit-transform: translateY(-5px);
  }
  4%,8%,12%,16% {
    -webkit-transform:translateY(5px)
  }
}

@-o-keyframes shake {
  0%,20%,100% {
    -webkit-transform: translateY(0);
  }
  2%,6%,10%,14%,18% {
    -webkit-transform: translateY(-5px);
  }
  4%,8%,12%,16% {
    -webkit-transform:translateY(5px)
  }
}

@keyframes shake {
  0%,20%,100% {
    -webkit-transform: translateY(0);
  }
  2%,6%,10%,14%,18% {
    -webkit-transform: translateY(-5px);
  }
  4%,8%,12%,16% {
    -webkit-transform:translateY(5px)
  }
}

.margin-r-15 {
  margin-right: 15px;
}

.margin-b-30 {
  margin-bottom: 30px;
}

.subpage-summary {
  .margin-b-40 {
    margin-bottom: 40px;
  }
  .margin-b-26 {
    margin-bottom: 26px;
  }
}

.form-group-margin-0 {
  .form-group {
    margin-bottom: 0;
  }
}

.results-text {
  font-size: 1.2rem;
  letter-spacing: 0.04rem;
  .results-number {
    font-size: 1.1rem;
    letter-spacing: 0.24rem;
    font-weight: bold;
    text-transform: uppercase;
    padding-left: 4px;
  }
  .arrow-down {
    display: block;
    width: 20px;
    height: 80px;
    background: url("../Svg/Pfeil_gross_unten.svg") center center no-repeat;
    background-size: 22px;
    margin-bottom: -5px;
    //-webkit-animation: shake 6s 1s infinite;
    //-moz-animation: shake 6s 1s infinite;
    //-o-animation: shake 6s 1s infinite;
    //animation: shake 6s 1s infinite;
  }
}

.search-icon {
  background: url("../Svg/Suche_blau.svg") right -20px top 60px no-repeat;
  background-size: 360px;
  &.smaller {
    background-size: 300px;
    background-position: top -30px right;
  }
}

.arrow-icon {
  background: url("../Svg/Inserieren_blau.svg") right -35px top 30px no-repeat;
  background-size: 400px;
  &.smaller {
    background-size: 280px;
    background-position: top -30px right -35px;
  }
}

.ajax-scroll-loader {
  position: relative;
  height: 204px;
  transition: all .6s;

  .uil-default-css {
    opacity: 0;
  }

  &.loader-hidden {
    height: 0;
    opacity: 0;
    overflow: hidden;
  }
}

.later-elements {
  overflow: hidden;

  .disturber {
    margin-bottom: 24px;
  }

  .practice-result, .device-result {
    opacity: 0;
    transition: all .6s;

    &.faded-in {
      opacity: 1;
    }
  }

  > .practice-result:first-child,
  > .job-result:nth-child(1),
  > .job-result:nth-child(2),
  > .device-result:nth-child(1),
  > .device-result:nth-child(2) {
    margin-top: 24px;
  }
}

.paragraph-fix {
  font-size: 1.28rem;
  line-height: 2.2rem;
  letter-spacing: 0.075rem;
  margin-top: 6px;
  margin-bottom: 0;
}

.file-upload-container > .row {
  margin-bottom: 10px;
  position: relative;

  .remove-image {
    font-size: 2rem;
    position: absolute;
    top: -3px;
    right: 15px;
    cursor: pointer;
    background-image: url("../Svg/X_schwarz.svg");
    width: 15px;
    height: 15px;
    background-size: cover;
  }

  .image-name {
    display: inline-block;
    height: 19px;
    max-width: 140px;
    overflow: hidden;
    overflow-wrap: normal;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.nav-pills, .nav-tabs {
  a {
    color: @color-bg-panel;
  }
}

.link-red {
  background: @color-font-error;

  &:before,
  &:after,
  .ws-link-inner:before,
  .ws-link-inner:after {
    border-color: @color-font-error !important;
  }
}

.no-padding-left {
  padding-left: 0;
}

.clearfix {
  clear: both;
}

.sp-image,
.slider-pro img.sp-image,
.slider-pro img.sp-thumbnail {
  max-width: 100%;
  height: auto !important;
  width: auto !important;
}

.x-button {
  cursor: pointer;
}