.stories-slider {
  background: @color-bg-stories-slider;
  color: @color-font-alt;
  padding: 38px 0;

  .carousel {

    .slider-controls {
      margin-top: -24px;
      padding-bottom: 23px;
    }
    .carousel-control {
      background: transparent;
      opacity: 1;
      transition: @transition-standard;
      &:hover, &:focus {
        opacity: 0.8;
      }
    }

    .slider-description {
      padding-bottom: 30px;
    }

    .slider-resource-full {
      padding-bottom: 50px;
    }

    .left {

      .glyphicon-chevron-left {
        background: url("../Svg/Pfeil_gross_links.svg") no-repeat;
        background-size: 37px;
        width: 40px;
        height: 37px;
        margin-left: -16px;
          &:before {
            content: "";
          }
      }
    }
    .right {

      .glyphicon-chevron-right {
        background: url("../Svg/Pfeil_gross_rechts.svg") no-repeat;
        background-size: 37px;
        width: 40px;
        height: 37px;
        right: 271px;
        &:before {
          content: "";
        }
      }

    }

    .carousel-indicators {
      width: 60px;
      bottom: -14px;
      left: 179px;

      li {
        border: none;
        background: @color-slider-indicator;
        width: 4px;
        height: 4px;
        margin: -2px 3px;
        &.active {
          background: @color-slider-indicator-active;
        }
      }
    }
  }

  .slider-caption {
    h3 {
      margin-top: 0;
      margin-bottom: 7px;
      line-height: 0.79em;
      letter-spacing: 0.09rem;
    }
    span {
      font-family: @font-family-headline;
      line-height: 6rem;
      display: block;
      padding-top: 16px;
      padding-left: 62px;
      font-size: 4.7rem;
    }
  }

  .slider-description {
    p {
      font-size: 1.1rem;
      line-height: 1.6rem;
      letter-spacing: 0.024rem;
      margin-bottom: 16px;
    }
    a {
      color: @color-font-alt;
    }
  }
}