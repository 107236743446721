.offer-options {
  .container {
    .row {
      &:last-child {
        .horizontal-line {
          display: none;
        }
      }
    }
  }
  .option {
    height: 100%;
    overflow: hidden;
    position: relative;
    min-height: 209px;
    margin-bottom: 38px;
    h3 {
      margin-top: 9px;
      margin-bottom: 32px;
      text-transform: uppercase;
      font-size: 2rem;
      letter-spacing: 0.31rem;
    }

    p {
      font-size: 1.17rem;
      letter-spacing: 0.047rem;
      line-height: 1.6rem;
    }

    .price-sticker {
      width: 95px;
      height: 95px;
      border-radius: 50%;
      background: @color-bg-price-sticker;
      color: @color-font-price-sticker;
      font-weight: bold;
      text-align: center;
      padding-top: 34px;
      font-size: 2rem;
      letter-spacing: 0.2rem;
      -webkit-transform: rotate(-12deg);
      -moz-transform: rotate(-12deg);
      -ms-transform: rotate(-12deg);
      -o-transform: rotate(-12deg);
      transform: rotate(-12deg);
      margin-top: 12px;
      margin-left: 36px;
    }

    .arrow {
      width: 60px;
      height: 30px;
      background: url("../Svg/Pfeil_gross_rechts.svg") center center no-repeat;
      background-size: 41px;
      margin-left: 26px;
      margin-top: 47px;
    }

    a.ws-link {
      margin-top: 51px;
      margin-left: 28px;
    }

    .horizontal-line {
      position: absolute;
      left: 15px;
      right: 15px;
      bottom: 0;
      height: 1px;
      background: 1px solid @color-font;
      padding: 0 15px;
    }

    ul.checkpoints{
      list-style: none;
      margin-top: 16px;
      margin-bottom: 16px;
      li {
        padding-left: 18px;
        font-size: 1.17rem;
        letter-spacing: 0.047rem;
        line-height: 1.6rem;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          left: -2px;
          top: 2px;
          background: url("../Svg/Check-Haekchen.svg") center center no-repeat;
          background-size: 10px;
          padding: 7px;
        }
      }
    }
  }

  &.centered {
    .option {
      h3 {
        padding-left: 58px;
        margin-top: 46px;
        .bold {
          font-weight: bold;
        }
      }
      .price-sticker {
        margin-top: 11px;
        margin-left: -80px;
      }
      .arrow {
        margin-left: 24px;
        margin-top: 42px;
      }
      a.ws-link {
        margin-left: 0;
        margin-top: 0;
        margin-bottom: 14px;
        width: 100%;
      }
      .button-group {
        overflow: auto;
        padding-left: 27px;
        padding-top: 27px;
        padding-right: 14px;
      }
      .horizontal-line {
        left: 23.7%;
        right: 26.2%;
      }
    }
  }
}