.flash-message {
  display: none;
  .page-loader;
  font-size: 1.5rem;

  &.flash-message-success {

    .flash-message-content {
      position: relative;
      .bg-success;
      .text-success;

      width: 100%;
      max-width: 400px;
      outline: 2px solid @alert-success-text;
      margin: 50px auto 0;
      padding: 45px 15px;
      text-align: center;
    }
  }
}