header {
  position: fixed;
  z-index: 200;
  width: 100%;
  background: @color-header-gradient-start;
  background: radial-gradient(circle closest-corner at 54% bottom, @color-header-gradient-start, @color-header-gradient-end); /* Standard syntax */
  top: 0;
  left: 0;

  .navigation-left {
    margin-top: 90px;

    .navbar-header {
      padding-right: 27px;
      transition: @transition-standard;
      .brand {
        img.company-logo {
          width: 172px;
        }
      }
      &:hover {
        transform: scale(1.1);
      }
    }

    transition: @transition-standard;

    &.collapsed {
      margin-top: 14px;

      .navbar-logo {
        margin-top: -34px;
      }

      nav.navbar {
        margin-top: -5px;
      }
    }

    nav.navbar {
      margin-bottom: -4px;
      margin-top: -7px;
      border: none;
      display: inline-block;
      .navbar-nav {
        li {
          margin: 0 2px;
          a {
            display: block;
            height: 50px;
            padding: 13px 17px 13px 14px;
            font-weight: bold;
            font-size: 0.846em;
            text-transform: uppercase;
            letter-spacing: 0.2em;
            .transition;

            &:before {
              content: "";
              opacity: 0;
              color: @color-font-navlink-active;
              transition: @transition-standard;
              font-family: 'Glyphicons Halflings';
              position: absolute;
              left: 45%;
              bottom: 0;
              font-size: 9px;
            }

            &:hover, &:focus,
            &.active {
              color: @color-font-navlink-active;
              &:before {
                content: "\e253";
                opacity: 1;
                bottom: 4px;
              }
            }

            &:hover, &:focus {
              background: none;
            }
          }
        }
      }
    }
  }

  .meta-nav {
    margin-top: 9px;
    z-index: 1000;
    .link-default {
      display: block;
      padding: 14px 28px 13px 14px;
      font-weight: normal;
      font-size: 0.846em;
      text-transform: uppercase;
      letter-spacing: 0.2em;
      transition: opacity, letter-spacing 0.4s;
      &:hover, &:focus {
        letter-spacing: 0.28rem;
      }
      &.user-logged-in {
        background: url("../Svg/Logout.svg") center center no-repeat;
        background-size: 19px;
        margin-right: 37px;
        margin-top: 4px;
        width: 23px;
        height: 37px;
        padding: 0;
        transition: opacity, transform 0.4s;
        &:hover, &:focus {
          transform: scale(1.1);
        }
      }
      &.login-panel-open {
        background: url("../Svg/X_weiss.svg") center center no-repeat;
        background-size: 19px;
        margin-right: 37px;
        margin-top: 4px;
        width: 23px;
        height: 37px;
        padding: 0;
        transition: opacity, transform 0.4s;
        &:focus {
          opacity: 1;
          transform: scale(1);
        }
        &:hover {
          transform: scale(1.1);
          opacity: 0.8;
        }
      }
    }
    .madeby-logo {
      height: 40px;
      overflow: hidden;
      span.by-text {
        font-size: 0.692em;
        margin-right: 3px;
        position: relative;
        top: -10px;
      }
      img {
        width: 90px;
        margin-top: -22px;
        margin-right: -1px;
      }
    }
  }

  .navbar-logo {
    height: 45px;
    overflow: hidden;
    position: relative;
    z-index: 5;

    img {
      // old logo:
      //margin-top: -58px;
      // new logo:
      margin-top: 0;
      margin-left: -1px;
    }
  }
}


.navigation-footer {
  background: @color-bg-panel-open;
  color: @color-font-alt;
  padding: 25px 0;

  .nav-block {
    list-style: none;
    padding-top: 15px;
    overflow: hidden;

    li {
      list-style: none;

      a {
        color: @color-bg-panel;
        font-weight: 700;
        margin-bottom: 7px;
        display: block;
        transition: @transition-standard;
        &.main-link {
          display: inline-block;
          font-size: inherit;
          &:hover {
            letter-spacing: 0.2rem;
          }
        }
        &:hover {
          letter-spacing: 0.1rem;
        }
      }
    }

    > li {
      width: ~"calc(20% - 20px)";
      margin-right: 25px;
      > a {
        text-transform: uppercase;
        color: @color-font-alt;
        margin-bottom: 15px;
        letter-spacing: 0.1em;
      }
      &.last {
        margin-right: 0;
      }
    }

    .subnavigation-footer a {
      font-size: 0.84em;
      letter-spacing: 0.05em;
      &:hover, &:focus {
        opacity: 0.9;
      }
    }
  }
}

.mobile-nav {
  will-change: left;
  width:100%;
  position: fixed;
  height: 100%;
  z-index: 9998;
  left: -100%;
  overflow-y: scroll;

  transition: @transition-standard;

  &.open {
    left: 0;
  }



  .mobile-nav-inner {
    position: absolute;
    width: 75%;
    background: @color-bg-mobile-navigation;
    overflow: hidden;
    height: 0;
    padding: 0;
    .inner-content {
      opacity: 0;
      transition: @transition-standard;
    }
    &.active {
      position: relative;
      -webkit-box-shadow: -3px 0px 67px -1px @color-shadow-mobile-navigation;
      -moz-box-shadow: -3px 0px 67px -1px @color-shadow-mobile-navigation;
      box-shadow: -3px 0px 67px -1px @color-shadow-mobile-navigation;
      height: 100%;
      min-height: 600px;
      overflow-y: scroll;
      padding-top: 100px;
      .inner-content {
        opacity: 1;
      }
    }

    ul.mobile-main {
      list-style: none;
      margin-left: 6px;
      li {
        padding: 14px 0;
        a {
          text-transform: uppercase;
          letter-spacing: 0.2rem;
        }
      }
    }

    .mobile-login-button {
      display: inline-block;
      text-transform: uppercase;
      background: transparent;
      border: 2px solid @color-font;
      padding: 4px 11px;
      letter-spacing: 0.2rem;
      margin-top: 20px;
      margin-left: 6px;
    }

    .step-back {
      position: absolute;
      top: 32px;
      right: 20px;
      background: @color-font;
      background-size: 12px;
      width: 26px;
      height: 3px;
      margin-top: 4px;
      &:before {
        content: "";
        width: 14px;
        display: block;
        height: 3px;
        transform: rotate(-45deg);
        background: @color-font;
        margin-top: -4px;
        margin-left: -2px;
      }
      &:after {
        content: "";
        width: 14px;
        display: block;
        height: 3px;
        transform: rotate(45deg);
        background: @color-font;
        margin-top: 5px;
        margin-left: -2px;
      }
    }

    .mobile-footer {
      background: @color-bg-mobile-menu-corner;
      width: 100%;
      &:before {
        content: "";
        border-left: 575px solid transparent;
        border-bottom: 80px solid @color-bg-mobile-menu-corner;
        position: absolute;
        top: -80px;
        left: 0;
        right: 0;
      }
      .mobile-footer-inner {
        list-style: none;
        margin-left: 6px;
        li {
          padding: 8px 0;
          a {
            text-transform: uppercase;
            letter-spacing: 0.2rem;
            font-size: 1.1rem;
          }
        }
      }
    }
  }
}