.detail-view {
  padding-bottom: 58px;

  .detail-view-title {
    text-transform: uppercase;
    font-size: 2rem;
    letter-spacing: 0.27rem;
    margin-top: 4px;
    margin-bottom: 20px;
  }

  .disturber {
    margin-top: 55px;
  }

  .subtitle {
    font-size: 1.15rem;
    letter-spacing: 0.06rem;
    line-height: 1.6rem;
    margin-bottom: 48px;
  }

  .x-button {
    width: 20px;
    height: 20px;
    background: url("../Svg/X.svg") center center no-repeat;
    background-size: 20px;
    transition-delay: 0.1s;
    transition: @transition-standard;
    &:hover, &:focus {
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      transform: rotate(-90deg);
    }
  }

  .detail-description-headline {
    font-size: 1.15rem;
    letter-spacing: 0.06rem;
    margin: 0 0 1px;
  }

  .detail-description {
    margin-bottom: 28px;
    p {
      font-size: 1.15rem;
      letter-spacing: 0.06rem;
      line-height: 1.6rem;
    }
    ul {
      list-style: none;
      margin-top: -10px;
      li {
        font-size: 1.15rem;
        letter-spacing: 0.06rem;
        line-height: 1.6rem;
      }
    }
  }

  ul.details-list {
    margin-bottom: 19px;
    margin-top: 0;

    li {
      display: block;
      font-size: 1.2rem;
      letter-spacing: 0.04rem;
      line-height: 1.6rem;
      padding-left: 21px;
      position: relative;
      margin-bottom: 7px;
      &.no-margin {
        margin-bottom: 0;
      }

      &:before {
        content: "";
        padding: 0 8px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }

      &.date {
        &:before {
          background: url("../Svg/Kalender.svg") center left no-repeat;
          background-size: 11px;
        }
      }
      &.time {
        &:before {
          background: url("../Svg/Uhr.svg") center left no-repeat;
          background-size: 12px;
        }
      }
      &.location{
        &:before {
          background: url("../Svg/Pin.svg") top 3px left no-repeat;
          background-size: 10px;
        }
      }
      &.price {
        &:before {
          background: url("../Svg/Euro.svg") top 3px left no-repeat;
          background-size: 8px;
        }
      }
      &.target {
        &:before {
          background: url("../Svg/Zielscheibe.svg") top 2px left no-repeat;
          background-size: 13px;
        }
      }
      &.participants {
        &:before {
          background: url("../Svg/Maennle.svg") center left no-repeat;
          background-size: 14px;
        }
      }
      &.speaker {
        &:before {
          background: url("../Svg/Sprechblase.svg") top 3px left no-repeat;
          background-size: 11px;
        }
      }
      &.space {
        &:before {
          background: url("../Svg/Praxisflaeche.svg") top 3px left no-repeat;
          background-size: 11px;
        }
      }
      &.rooms {
        &:before {
          background: url("../Svg/Behandlungszimmer.svg") top 3px left no-repeat;
          background-size: 11px;
        }
      }
      &.field {
        &:before {
          background: url("../Svg/Fachrichtung.svg") top 3px left no-repeat;
          background-size: 11px;
        }
      }
      &.patients {
        &:before {
          background: url("../Svg/Patientenfaelle.svg") top 3px left no-repeat;
          background-size: 11px;
        }
      }
      &.turnover {
        &:before {
          background: url("../Svg/Jahresumsatz.svg") top 3px left no-repeat;
          background-size: 11px;
        }
      }
      &.profit{
        &:before {
          background: url("../Svg/Jahresgewinn.svg") top 3px left no-repeat;
          background-size: 11px;
        }
      }
      &.employees {
        &:before {
          background: url("../Svg/Anzahl-Mitarbeiter.svg") top 3px left no-repeat;
          background-size: 11px;
        }
      }
      &.practice-form {
        &:before {
          background: url("../Svg/Praxisform.svg") top 3px left no-repeat;
          background-size: 11px;
        }
      }
      &.equipment {
        &:before {
          background: url("../Svg/Ausstattung.svg") top 3px left no-repeat;
          background-size: 11px;
        }
      }
      &.manufacturer {
        &:before {
          background: url("../Svg/Hersteller.svg") center left no-repeat;
          background-size: 11px;
        }
      }
      &.type {
        &:before {
          background: url("../Svg/Typ.svg") center left no-repeat;
          background-size: 11px;
        }
      }
      &.margin-top-lh {
        margin-top: ~"calc(1.6rem + 7px)";
      }
    }
    &.no-icons {
      li {
        padding: 0;
        &:before {
          display: none;
        }
      }
    }
  }
  a {
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
  }
  .send-message {
    margin-top: 54px;
    margin-bottom: 92px;
    h4 {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 2rem;
      letter-spacing: 0.26rem;
      margin-bottom: 25px;
    }
    p {
      font-size: 1.15rem;
      letter-spacing: 0.06rem;
      line-height: 1.6rem;
      width: 75%;
      margin-top: 20px;
    }
  }

  .detail-content {
    .login-prompt {
      padding-top: 40px;
    }
  }
}