.login-prompt {
  padding-top: 0px;
  color: @color-font;
  .pre-headline {
    font-weight: bold;
    .pre-pre {
      font-size: 1.2rem;
      text-transform: none;
      letter-spacing: 0.35rem;
    }
    .pre-big {
      font-size: 1.95rem;
      letter-spacing: 0;
      margin-left: -5px;
      padding-right: 15px;
    }
  }
  h4 {
    text-transform: uppercase;
    font-size: 1.95rem;
    letter-spacing: 0.38rem;
    display: inline-block;
    margin-bottom: 14px;
    font-weight: bold;
  }
  p {
    text-transform: none;
    font-size: 1.16rem;
    letter-spacing: 0.05rem;
    line-height: 1.6rem;
    margin-bottom: 15px;
  }

  &.light-bg {
    color: @color-font-alt;
  }
}