.search {
  position: relative;

  &:hover {
    button img {
      transform: scale(1.1);
    }
  }

  [type="text"] {
    color: @color-font;
    font-size: 0.9em;
    font-weight: 700;
    letter-spacing: .14em;
    padding: 4px 6px;
    &:focus {
      //text-align: left;
    }
    &:valid {
      //text-align: center;
      &:focus {
        //text-align: left;
      }
    }
  }

  .search-inner {
    padding-left: 92px;
    padding-right: 94px;
  }

  button {
    height: 60px;
    position: absolute;
    right: 16px;
    top: -15px;
    width: 60px;
    img {
      transition: @transition-standard;
    }
  }

  ::-webkit-input-placeholder {
    color: @color-font-navlink-active !important;
    text-transform: uppercase;
  }
  :-moz-placeholder {
    color: @color-font-navlink-active !important;
    text-transform: uppercase;
  }
  ::-moz-placeholder {
    color: @color-font-navlink-active !important;
    text-transform: uppercase;
  }
  :-ms-input-placeholder {
    color: @color-font-navlink-active !important;
    text-transform: uppercase;
  }
}
