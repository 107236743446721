footer {
  background: @color-bg-footer;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 210;
  .footer-navigation {
    list-style: none;
    li {
      float: left;
      a {
        display: block;
        padding: 11px 17px 11px 19px;
        font-weight: bold;
        font-size: 0.846em;
        text-transform: uppercase;
        letter-spacing: 0.2em;
        transition: @transition-standard;
        &.first {
          padding-left: 0;
        }
        &:hover {
          color: @color-font-navlink-active;
        }
      }
    }
  }

  .copyright {
    font-size: 0.846em;
    padding: 10px 17px 10px 19px;
    letter-spacing: 0.2em;
  }
}