.contact-address {
  .text-block {
    margin-bottom: 25px;
    p {
      margin-bottom: 0;
      width: 100%;
      line-height: 2.5rem;
    }

    ul {
      list-style-type: decimal;
      margin-left: 25px;
      margin-top: 25px;

      li {
        margin-bottom: 10px;
        line-height: 2rem;
      }

      ul {
        margin-top: 10px;
        list-style: lower-alpha;
      }
    }
  }
}

.contact-map {
  position: relative;
  overflow: hidden;
  width: 100%;
  .map-google {
    height: 301px;
    background: url("../Img/map_contact.jpg") center center no-repeat;
    background-size: cover;
    transition: @transition-standard;
    position: relative;
    -webkit-filter: grayscale(100%); /* Chrome, Safari, Opera */
    filter: grayscale(100%);
    &:hover {
      transform: scale(1.01);
      -webkit-filter: grayscale(0%);
      filter: grayscale(0%);
    }
    .google-link {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 2;
    }
  }
  .marker {
     position: absolute;
     left: ~"calc(50% - 13px)";
     top: ~"calc(50% - 35px)";
     width: 26px;
     height: 40px;
     background: url("../Svg/Pin_Maps.svg") center center no-repeat;
     background-size: 24px;
   }
}