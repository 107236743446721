@media only screen and (max-width: 1259px) {

  header {
    .navigation-left {
      nav {
        &.navbar {
          .navbar-nav {
            li {
              a {
                padding: 13px 13px 13px 14px;
              }
            }
          }
        }
      }
    }
  }

  .ad-box .container .column {
    width: 177px;
    &.collapsed {
      top: 130px;
    }
  }

  .login-layer-container {
    &.header-collapsed {
      margin-top: -60px;
    }
  }

  .stories-slider .carousel {
    .right .glyphicon-chevron-right {
      right: 25px;
    }

    .carousel-indicators {
      margin-left: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

}

@media only screen and (max-width: 992px) {

  body {
    padding-top: 0;
  }

  header {
    //background: none;
    position: relative;

    .meta-nav {
      //display: none;
    }

    #hamburger {
      display: block;
    }

    .navigation-left {
      margin-top: 0;
      nav.navbar-top {
        //display: none;
        li {

          &:first-child {
            a {
              padding-left: 0;
            }
          }

          &:last-child {
            a {
              padding-right: 0;
            }
          }
        }
      }

      .navbar-header {
        //.pull-right;
        padding-right: 0;
        margin-top: 15px;
        margin-right: 5px;
      }

      &.collapsed {
        margin-top: 0;
      }
    }

  }

  .faq-panel .panel .panel-content .close-panel-button {

  }

  .ws-modal .ws-modal-inner {
    width: 70%;
    margin-left: 15%;
  }

  .startpage .navigation-footer .nav-block>li {
    width: 31%;
    margin-right: 10px;
    min-height: 130px;
  }

  .ad-box .container .column {
    width: 140px;
    &.collapsed {
      top: 100px;
    }
  }

  .quote-box .quote {
    padding-top: 20px;
    padding-bottom: 10px;
  }

  .success-slider {
    .carousel-inner {
      .item.left, .item.right {
        .content-inner {
          .content-right {
            margin-left: 0 !important;
          }
        }
      }
    }
  }

  .offer-options.centered .option .price-sticker {
    margin-left: 0;
    margin-bottom: 30px;
  }

  .offer-options.centered .option .horizontal-line {
    left: 10%;
    right: 10%;
  }

  .stories-slider {
    .slider-resource {
      padding-bottom: 25px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .subpage-summary {
    .right-col {
      .subpage-summary-inner {
        padding-left: 0px;
      }
    }

    .left-col {
      .subpage-summary-inner {
        padding-right: 0px;
      }
    }
  }

  .success-slider {
    .carousel {
      .carousel-inner {
        .carousel-content {
          .background-number {
            margin-left: -200px;
            font-size: 60rem;
          }
        }
      }
    }
  }

  body {
    padding-top: 0;
    padding-bottom: 0;
  }

  header {
    background: none;
    position: relative;

    .meta-nav {
      display: none;
    }

    #hamburger {
      display: block;
    }

    .navigation-left {
      margin-top: 0;
      nav.navbar-top {
        display: none;
      }

      .navbar-header {
        .pull-right;
        padding-right: 0;
        margin-top: 15px;
        margin-right: 5px;
      }

      &.collapsed {
        margin-top: 0;
      }
    }

  }

  .faq-panel .panel {
    margin-bottom: 0;

    .mobile .close-panel-button {
      opacity: 1;
    }
  }

  .ws-modal {
    padding-top: 22%;
    .ws-modal-inner {
      width: 100%;
      margin-left: 0;
      a {
        width: 70%;
      }
    }
  }

  .startpage .navigation-footer .nav-block>li {
    width: 31%;
    margin-right: 10px;
    min-height: 130px;
  }

  .detail-view ul.details-list {
    margin-top: 20px;
  }

  // Reset broken cols for responsive
  .faq-panel .panel .mobile {
    padding-left: 15px;
    margin-left: 15px;
    padding-right: 15px;
  }

  .button-row {
    button {
      width: ~"calc(50% - 10px)";
      &.margin-r-15 {
        margin-right: 15px;
      }
    }
  }

  .search-form {
    .search-button {
      .pull-right;
    }
  }

  .form-group {
    //overflow: hidden;
  }

  .practice-offer-new {
    .tab-style-radio {
      .radio {
        width: ~"calc(50% - 15px)";
        &:nth-child(even) {
          margin-right: 0;
        }
      }
    }
  }

  .contact-description {
    .display-flex {
      display: block;
      .pull-bottom {
        position: relative;
        margin-top: 20px;
      }
    }
  }

  .subpage-summary {
    margin-bottom: 0;
  }
  .subpage-summary-inner {
    margin-bottom: 40px;
  }

  .form-style {
    .padding-r-5,
    .padding-l-5 {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .startpage {
    background: @color-bg-gradient-start; /* For browsers that do not support gradients */
    background: -webkit-linear-gradient(left top, @color-bg-gradient-end, @color-bg-gradient-start); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(bottom right, @color-bg-gradient-end, @color-bg-gradient-start); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(bottom right, @color-bg-gradient-end, @color-bg-gradient-start); /* For Firefox 3.6 to 15 */
    background: linear-gradient(to bottom right, @color-bg-gradient-end, @color-bg-gradient-start); /* Standard syntax */
    .startpage-layout {
      background: url("../Img/map_mobile.png") center top -72px no-repeat;
      background-size: 160%;
    }
    .container.main {
      h2 {
        font-size: 2rem;
        margin-top: 50px;
        padding: 0;
      }
      h1.title {
        font-size: 5rem;
        margin-top: 25px;
        padding: 0;
      }
      p {
        font-size: 1rem;
        line-height: 1.4rem;
        margin-left: 0;
        margin-bottom: 60px;
        width: 100%;
      }
    }
    .navigation-footer .nav-block {
      padding-top: 0;
      > li {
        width: 100%;
        text-align: center;
        min-height: 0;
      }
    }
    .search {
      .search-inner {
        padding: 0 25px;
      }
      button {
        height: 26px;
        width: 35px;
        top: 0;
        right: -10px;
      }
    }
    .arrow-down {
      padding-top: 50px;
    }
  }

  .page-summary {
    .text-center;
    h1.title {
      font-size: 4.5rem;
      padding: 0;
    }
    p {
      width: 100%;
    }
  }

  .xs-margin {
    margin-bottom: 15px;
  }

  .stories-slider .carousel .slider-controls {
    margin-top: 30px;
    .glyphicon-chevron-right {
      right: 20px;
    }
    .carousel-indicators {
      bottom: -16px;
      margin-left: -30px;
      left: 50%;
    }
  }

  .events-list .event-content, .search-results .job-description, .search-results .practice-description {
    h3, h3.title {
      font-size: 1.4rem;
      letter-spacing: 0.11rem;
    }
  }

  .slider-range {
    width: ~"calc(100% - 23px)";
    margin-top: 0;
    margin-left: 0;
    top: 25px;
    margin-bottom: 50px;
  }

  .success-slider {
    .carousel-indicators {
      width: 90%;
      margin-left: -40%;
    }
    .carousel-control {
      display: none;
    }
  }

  .blink-5 {
    margin-top: 80%;
  }
  .blink-6 {
    margin-top: 100%;
  }

  .checkbox {
    width: 100%;
  }

  .button-row {
    button {
      min-height: 4.8rem;
    }
  }

  .search-results .device-result, .search-results .job-result, .search-results .practice-result {
    overflow: hidden;
  }


}
