.portfolio-page {
  .default-section {
    padding-bottom: 12px;
  }
}

.faq-panel {
  padding-bottom: 44px;
  .panel {
    clear: both;
    margin: 0;
    border: none;
    background: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-bottom: 40px;

    .panel-heading {
      border: none;

      &.active {
        .pre-title {
          color: @color-font;
        }
      }
      .pre-title {
        color: @color-font-headline-pre;
        height: 100%;
        font-size: 2rem;
        letter-spacing: 0.28rem;
        line-height: 2rem;
        .pull-left;
        transition: @transition-standard;
      }
      .panel-heading-content {
        padding-left: 75px;
        padding-right: 85px;
        h3 {
          font-size: 2rem;
          letter-spacing: 0.28rem;
          text-transform: uppercase;
          margin: 0;
          margin-bottom: 19px;
        }

        p {
          margin: 0;
          margin-bottom: 5px;
          font-size: 1.18rem;
          line-height: 1.6rem;
          letter-spacing: 0.04rem;
          font-weight: normal;
        }

        a[data-toggle="collapse"] {
          transition: @transition-standard;
          background: none;

          &:before {
            content: "+";
            padding-right: 4px;
          }
        }

        a[aria-expanded="true"] {
          color: transparent;
          font-size: 0px;
          width: 24px;
          height: 42px;
          background: url("../Svg/Pfeil_Akkordeon.svg") no-repeat center center;
          background-size: 24px;
          left: 0;
          margin-bottom: 19px;
        }

      }
    }


    .panel-content {
      background: @color-bg-panel-open;
      color: @color-font-alt;
      //-webkit-box-shadow: inset 0px 0px 8px 1px rgba(0,0,0,0.7);
      //-moz-box-shadow: inset 0px 0px 8px 1px rgba(0,0,0,0.7);
      //box-shadow: inset 0px 0px 8px 1px rgba(0,0,0,0.7);
      .panel-content-inner {
        min-height: 0;

        a {
          color: @color-font-alt;
          font-weight: bold;
          text-transform: uppercase;
        }

        .panel-collapse.collapsing {
          .close-panel-button {
            right: 0;
          }
        }
      }
      .close-panel-button {
        position: absolute;
        background: url("../Svg/X.svg") no-repeat center center;
        background-size: 24px;
        width: 20px;
        height: 20px;
        top: 28px;
        right: 15px;
        opacity: 0;
        transition: opacity 0.4s;
        transition-delay: 0.2s;
        &[aria-expanded="true"] {
          opacity: 1;
        }
      }

      h4 {
        margin-top: 52px;
        margin-bottom: 26px;
        text-transform: uppercase;
        font-size: 2rem;
        font-weight: bold;
        letter-spacing: 0.28rem;
        &.text-cols-1 {
          margin-left: 25%;
          margin-right: 25%;
        }
      }

      p {
        font-size: 1.18rem;
        line-height: 1.6rem;
        letter-spacing: 0.04rem;
        font-weight: normal;
        margin-bottom: 52px;
        &.text-cols-1 {
          margin-left: 25%;
          margin-right: 25%;
        }
        &.text-cols-documents {
          width: ~"calc(50% - 30px)";
          display: inline-block;
        }
      }
      ul.documents {
        width: ~"calc(50% - 30px)";
        .pull-left;
        list-style: none;
        li {
          padding-left: 56px;
          margin-bottom: 22px;
          position: relative;
          &:before {
            content: "";
            position: absolute;
            left: 19px;
            top: 0;
            height: 20px;
            background: url("../Svg/PDF-Zeichen.svg") center center no-repeat;
            background-size: 20px;
            padding: 0 10px;
          }
          a {
            color: @color-font-alt;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 1.1rem;
            letter-spacing: 0.23rem;
          }
        }
      }
    }
    .mobile {
      .panel-content;
      position: relative;
      h4 {
        margin-top: 0;
        padding-top: 20px;
      }
      p {
        margin-bottom: 0;
        padding-bottom: 20px;
      }
      .close-panel-button {
        &.collapsing {
          right: 15px;
        }
      }
    }
  }
}

.faq-panel .panel-content-inner a {
  color: #000;
  font-weight: 700;
  text-transform: uppercase;
}