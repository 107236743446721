.page-headline {
  color: @color-font-navlink-active;
  font-size: 1.55em;
  padding-left: 0;
  padding-top: 25px;
  position: relative;
  font-weight: 700;
  letter-spacing: 0.125em;
  padding-bottom: 50px;

  .icon {
    display: inline-block;
    top: 0;
    left: 0;
    margin-top: -28px;

    // default:
    img {
      width: 50px;
    }

    .icon-1 {
      width: 50px;
      margin-left: -1px;
      margin-top: -3px;
      margin-right: 15px;
    }
    .icon-2 {
      width: 66px;
      margin-left: 0;
      margin-top: -16px;
      margin-right: 13px;
    }
    .icon-3 {
      width: 27px;
      margin-left: -1px;
      margin-top: -1px;
      margin-right: 4px;
    }
    .icon-4 {
      width: 68px;
      margin-left: -1px;
      margin-top: -7px;
      margin-right: 8px;
    }
    .icon-5 {
      width: 46px;
      margin-left: -1px;
      margin-top: 2px;
      margin-bottom: -3px;
      margin-right: 11px;
    }
    .icon-6 {
      width: 56px;
      margin-right: 16px;
      margin-bottom: -16px;
    }
    .icon-7 {
      width: 38px;
      margin-bottom: -16px;
    }
    .icon-8 {
      width: 50px;
      margin-top: 8px;
      margin-bottom: -16px;
      margin-right: 22px;
    }
    .icon-9 {
      width: 50px;
      margin-bottom: -12px;
      margin-right: 21px;
    }
  }

  .subpage-headline {
    color: @color-bg-panel-open;
    padding-left: 6px;
  }

  .letter {
    font-size: 0.6em;
    letter-spacing: 4px;
  }

  .number {
    margin-left: -9px;
    margin-right: 6px;
  }
}

.light-bg {
  .page-headline {
    color: @color-font-headline-alt;
  }
  .subpage-headline {
    color: @color-font-headline-sub-alt;
  }
}