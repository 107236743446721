.quote-box {
  background: @color-bg-quote-box;


  .quote {
    font-family: Manus,"Noto Sans",sans-serif;
    width: 100%;
    padding: 103px 0;
    font-size: 2.85em;
    line-height: 6.5rem;

    .quote-big {
      font-family: @font-family-headline;
      padding-left: 46px;
      font-size: 5.49rem;
    }

    &:before {
      content: "\00bb";
    }

    &:after {
      content: "\00ab";
      padding-left: 8px;
    }
  }

  .quote-img {
    height: 376px;
  }
}