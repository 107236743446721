input[type="text"],
input[type="tel"],
input[type="email"],
input[type="password"],
textarea,
textarea.form-control {
  border: none;
  box-shadow: none;
  background: @color-bg-input;
  color: @color-font-input-alt;
  padding: 2px 11px;
  font-size: 1.15rem;
  height: auto;
  letter-spacing: 0.06rem;
  line-height: 1rem;
  transition: outline 1s;
}

select {
  opacity: 0;
  height: 23px !important;
}


input[type="text"],
input[type="tel"],
input[type="email"],
input[type="password"],
textarea {
  &:focus {
    outline: 1px solid @color-border-input-alt !important;
    outline-offset: 0;
    box-shadow: none;
  }
  &:hover {
    outline: 1px solid lighten(@color-border-input-alt, 20);
  }
}

.select2-container {
  &:focus {
    .select2-selection {
      outline: 1px solid @color-border-input-alt !important;
      outline-offset: 0;
      box-shadow: none;
    }
  }
  &:hover {
    .select2-selection {
      outline: 1px solid lighten(@color-border-input-alt, 20);
    }
  }
}

.select2-container--focus, .select2-container--open {
  .select2-selection {
    outline: 1px solid @color-border-input-alt;
  }
}

.select2-container--default .select2-search--dropdown .select2-search__field:focus {
  outline: 1px solid @color-border-input-alt !important;
  border: 1px solid transparent;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: @color-font-input-placeholder !important;
}

::-webkit-input-placeholder {
  color: @color-font-input-placeholder !important;
}
:-moz-placeholder {
  color: @color-font-input-placeholder !important;
}
::-moz-placeholder {
  color: @color-font-input-placeholder !important;
}
:-ms-input-placeholder {
  color: @color-font-input-placeholder !important;
}

label {
  color: @color-font-alt;
  font-size: 1.15rem;
  letter-spacing: 0.06rem;
  font-weight: normal;
  margin-bottom: 8px;
}

input[type="submit"], input[type="reset"], button[type="submit"] {
  .ws-link;

  &.search-button {
    border: none;
    background: url("../Svg/Lupe_klein_weiss.svg") center center no-repeat @color-bg-button;
    background-size: 20px;
    width: 33px;
    height: 25px;
    margin-top: -1px;
    margin-left: 18px;
    margin-bottom: 16px;
  }
}

.form-dark-bg {

  input[type="text"],
  input[type="email"],
  input[type="password"],
  textarea {
    background: @color-bg-input-dark;
    color: @color-font-input;
    outline: 1px solid @color-border-input;
    padding: 4px 11px;
    line-height: 1rem;

    &:focus {
      outline: 1px solid darken(@color-border-input, 30) !important;
    }
  }

  .select2-container {
    &:focus {
      .select2-selection {
        outline: 1px solid @color-border-input-alt;
        outline-offset: 0;
        box-shadow: none;
      }
    }
    &:hover {
      .select2-selection {
        outline: 1px solid @color-border-input-alt
      }
    }
  }

  label {
    color: @color-font;
  }

  ::-webkit-input-placeholder {
    color: @color-font-input-placeholder-light !important;
  }
  :-moz-placeholder {
    color: @color-font-input-placeholder-light !important;
  }
  ::-moz-placeholder {
    color: @color-font-input-placeholder-light !important;
  }
  :-ms-input-placeholder {
    color: @color-font-input-placeholder-light !important;
  }

  .select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: @color-font-input-placeholder-light !important;
  }

  .select2-container--default .select2-selection--single .select2-selection__rendered {
    padding: 4px 19px 4px 11px;
    color: @color-font;
  }

  .select2-container--default .select2-selection--single {
    background: transparent;
  }

  .select2-container--focus {
    //outline: 1px solid @color-border-input !important;
  }

  .select2-container {
    color: @color-font;
    width: 100% !important;
    margin-top: 1px;
    .select2-selection {
      outline: 1px solid @color-border-input;
    }
  }

  .select2-container--default .select2-selection--multiple,
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    background: url("../Svg/Formular_Drop-down-Pfeil_weiss.svg") center center no-repeat;
    background-size: 12px;
  }

  .select2-container--open .select2-dropdown--below {
    margin-top: 4px;
  }

  .tab-style-radio .radio input[type=radio]:checked~label {
    color: @color-font-radiotab-active;
    border: 1px solid @color-bg-radiotab-active;
  }

  .tab-style-radio .radio label {
    color: @color-font-input;
  }

  .radio-buttons div.radio label {
    height: 24px;
    border: 1px solid @color-border-input;
    padding-top: 3px;
  }

}

.radio {
  display: inline-block;
  min-width: 50%;
  .pull-left;
  margin-bottom: 2px;
  margin-top: 2px;
  &+.checkbox {
    margin-top: auto;
  }
  input[type="radio"] {
    display: none;
  }
  label {
    cursor: pointer;
    font-weight: normal;
    color: @color-font-alt;
    padding-left: 0;
  }
  input[type="radio"] + label:before {
    background: @color-bg-input;
    content: "•";
    display: inline-block;
    font-family: "Glyphicons Halflings", sans-serif;
    font-size: 3.6rem;
    color: transparent;
    height: 14px;
    width: 14px;
    margin: 1px 0.5rem 0 0;
    padding: 0;
    vertical-align: top;
    line-height: 5px;
    text-align: center;
    transition: @transition-standard;
    border-radius: 50%;
  }
  input[type="radio"]:checked + label:before {
    color: @color-radiobox-inner;
  }
}

.tab-style-radio {
  width: 100%;
  position: relative;
  display: block;

  .radio input[type=radio]+label:before {
    display: none;
  }

  .radio {
    position: relative;
    width: ~"calc(25% - 22.5px)";
    margin-right: @grid-gutter-width;
    display: block;
    margin-top: 10px;
    min-width: 0;
    .pull-left;

    &:last-child {
      margin-right: 0;
    }
    input[type="radio"] {
      display: none;
      &:checked ~ label {
        background: @color-bg-radiotab-active;
      }
    }
    label {
      position: relative;
      display: block;
      background: transparent;
      border: 1px solid @color-bg-radiotab-active;
      padding: 3px 9px;
      transition: @transition-standard;
      &:hover {
        border: 1px solid @color-border-input-alt;
      }
      &:focus {
        border: 1px solid darken(@color-border-input, 50);
        outline: none;
      }
    }
  }
}

.hide-day {
  [name *= day] {
    display: none;

    & + .select2-container {
      display: none;
    }
  }
}

textarea,
textarea.form-control {
  resize: none;
  padding: 4px 11px;
  height: 108px;
}

button {
  border: none;
}

.form-group {
  margin-bottom: 10.3px;
}

.slider-range {
  position: relative;
  width: 250px;
  height: 1px;
  background: @color-font;
  margin-top: 12px;
  margin-left: 136px;
  margin-bottom: 36px;

  .ui-slider-range {
    position: absolute;
    height: 3px;
    background: @color-font;
    margin-top: -1px;
  }

  .ui-slider-handle {
    display: block;
    position: absolute;
    min-width: 23px;
    height: 23px;
    background: @color-bg-panel;
    outline: 1px solid @color-font;
    text-align: center;
    padding: 3px 6px;
    font-weight: normal;
    font-size: 1.2rem;
    margin-top: -10px;
    cursor: pointer;

    &.ui-state-active {
      transform: scale(1.1);
    }
    &.ui-state-hover {

    }
  }
}

.ranges {
  label {
    color: @color-font;
    float: left;
    width: 21%;
    margin: 0;
    margin-top: -7px;
  }
}

.radio-hide-none {
  div.radio:first-of-type {
    display: none;
  }
}

.radio-buttons {
  margin-top: 30px;
  div.radio {
    width: 100%;
    margin-top: 0;
    margin-bottom: 10.3px;

    label {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      height: 23px;
      padding-top: 2px;
    }
  }
}

.button-row {
  margin: 2rem 0;
}

.radio-half-size {
  div.radio {
    width: 49.2%;
    margin-top: 0;
    margin-bottom: 10.3px;
    margin-right: 1.6%;

    &:nth-child(3n+0) {
      margin-right: 0;
    }

    label {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      height: 25px;
      padding-top: 4px;
    }
  }
}

.with-button {
  .pull-left;
  width: ~"calc(100% - 51px)";
}

.form-group {
  .select2-container--default {
    width: 100% !important;
  }
}

.form-style {
  .month-year-selector {
    .col-xs-10;
    .pull-right;
    .select2-container--default {
      width: ~"calc(50% - 17px) !important";
      &:last-of-type {
        margin-left: 30px;
        margin-right: -13px;
      }
    }
  }

  .padding-r-5 {
    padding-right: 5px;
  }

  .padding-l-5 {
    padding-left: 5px;
  }

  .day-month-year-selector {
    .select2-container--default {
      width: ~"calc(33.3% - 3px) !important";
    }
  }

  label.control-label {
    .col-xs-2;
    margin-bottom: 1px;
  }
}

.search-form {
  .padding-r-5 {
    padding-right: 5px;
  }

  .padding-l-5 {
    padding-left: 5px;
  }
  .margin-top-1 {
    margin-top: 1px;
  }
}


.checkbox {
  display: inline-block;
  width: 50%;
  .pull-left;
  margin-top: 2px;
  margin-bottom: 2px;
  &+.checkbox {
     margin-top: 2px;
   }
  input[type="checkbox"] {
    display: none;
  }
  label {
    cursor: pointer;
    font-weight: normal;
    color: @color-font-alt;
    padding-left: 0;
    letter-spacing: 0.02rem;
  }
  input[type="checkbox"] + label:before {
    background: @color-bg-input;
    content: "\e013";
    display: inline-block;
    font-family: "Glyphicons Halflings", sans-serif;
    font-size: 0.8rem;
    color: transparent;
    height: 14px;
    width: 14px;
    margin: 1px 0.5rem 0 0;
    padding: 0;
    vertical-align: top;
    line-height: 14px;
    text-align: center;
    transition: @transition-standard;
  }
  input[type="checkbox"]:checked + label:before {
    color: @color-checkbox-inner;
  }
}

.other-checkbox-wrap {
  position: relative;

  .other-checkbox {

    &:before {
      cursor: pointer;
      position: absolute;
      background: @color-bg-input;
      content: "\e013";
      display: inline-block;
      font-family: "Glyphicons Halflings", sans-serif;
      font-size: 0.8rem;
      color: transparent;
      height: 14px;
      width: 14px;
      margin: 1px 0.5rem 0 0;
      padding: 0;
      vertical-align: top;
      line-height: 14px;
      text-align: center;
      transition: @transition-standard;
    }

    &.active:before {
      color: @color-checkbox-inner;
    }
  }
}

.plain-text {
  margin-bottom: 7px;
}

.number-doctors {
  padding: 5px 0;
}

.form-group {
  div:not(".inline") {
    .radio + .radio {
      margin-top: 2px;
    }
  }
  .tab-style-radio:not(".inline") {
    .radio+.radio {
      margin-top: 0;
    }
  }
}

.add-image {
  width: 120px;
  height: 75px;
  background-color: @color-bg-input;
  text-align: center;
  line-height: 75px;
  font-size: 2rem;
  cursor: pointer;
}

.file-upload {
  display: none !important;
}

.searchbox-wrapper {
  .searchbox-left {
    width: 64%;
    margin-left: 1px;
  }

  .searchbox-right {
    width: 34.3%;
    margin-top: 1px;
    margin-right: 1px;
  }
  button {
    margin-top: 14px;
  }
}

.button-row {
  .margin-r-15 {
    margin-right: 15px;
  }
  &.marginfix-device {
    margin-left: -1px;
    margin-top: 17px;
  }
}

.search-form {
  padding-bottom: 15px;
}

.group-checkboxes-with-input {
  input[type="text"] {
    width: ~"calc(100% - 21px)";
    .pull-right;
    //margin-top: -23px;
    z-index: 2;
  }
}

.ws-error {
  outline: 1px solid red;
  // reset outline on select2 container, firefox breaks when a parent and a child have outlines
  &:hover, &:focus {
    outline: none;
  }
  &.select2-container {
    outline: none;
    .select2-selection {
      outline: 1px solid red;
    }
  }
}

.fos_user_registration_register {
  button.ws-link {
    margin-bottom: 15px;
  }
}

.overview-layout {
  .marginfix-device {
    margin-top: 20px;
  }
}

[name="search_practice"] .radio-buttons {
  margin: 0;
}

.privacy-wrap {
  .checkbox {
    float: none!important;
    width: 100%;
  }

  a {
    color: @color-bg-panel;

    &:hover, &:focus {
      opacity: 0.9;
    }
  }
}

.newsletter-wrap {
  .checkbox {
    float: none!important;
    width: 100%;
  }
}
