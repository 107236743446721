.subpage-summary {
  margin-bottom: 30px;

  img {
    width: 60px;
    &.icon-1 {
      width: 45px;
      padding-bottom: 11px;
    }
    &.icon-2 {
      width: 45px;
      padding-bottom: 14px;
    }
  }

  h3 {
    font-size: 1.6em;
    font-weight: 700;
    letter-spacing: 0.1em;
    margin-bottom: 33px;
  }

  h4 {
    text-transform: uppercase;
    font-size: 2rem;
    letter-spacing: 0.26rem;
    line-height: 2.6rem;
    margin-bottom: 40px;
  }

  p {
    font-size: 0.91em;
    line-height: 1.5em;
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .right-col {
    .subpage-summary-inner {
      padding-left: 20px;
    }
  }

  .left-col {
    .subpage-summary-inner {
      padding-right: 20px;
    }
  }
  .subpage-icon {
    &.eight-steps {
      img {
        width: 58px;
        margin-top: 2px;
      }
    }
    &.portfolio {
      img {
        width: 38px;
        margin-top: 10px;
        margin-bottom: 32px;
      }

    }
    &.seminars {
      img {
        width: 45px;
        margin-top: 7px;
        margin-bottom: 31px;
      }
    }
  }
}