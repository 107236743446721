.disturber {
  color: @color-font;
  padding: 10px 20px;
  background: url("../Svg/Lupe_klein_weiss.svg") top -16px right 20px no-repeat @color-bg-panel;
  background-size: 82px;
  transition: @transition-standard;
  .disturber-title {
    margin: 0;
    font-size: 2rem;
    letter-spacing: 0.31rem;
    line-height: 3.4rem;
  }
  .disturber-text {
    font-size: 1.2rem;
    line-height: 1.5rem;
    letter-spacing: 0.034rem;
    margin: 0;
  }

  a {
    margin-top: 4px;
  }
  &:hover {
    background-size: 90px;
  }
}