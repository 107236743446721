.login-layer-container {
  z-index: 200;
  position: fixed;
  margin-top: -77px;
  transition: @transition-standard;
  right: 0;

  &.header-collapsed {
    position: fixed;
    z-index: 199;
    margin-top: -70px;
  }
}

.startpage {
  .login-layer-container {
    position: relative;
    margin-top: 0;
    top: 75px;
  }
}

.login-input-helper {
  input {
    margin-bottom: 10px;
    font-weight: normal;
    font-size: 1.2rem;
    padding-left: 10px;
    font-weight: normal;
    letter-spacing: 0.01rem;
    color: @color-font;

    &[type="text"], &[type="password"] {
      width: 100%;
      height: 19px;
      background: transparent;
      border: @button-border-width solid @color-border-input;
    }

    &[type="password"] {
      width: 100%;
    }

    &[type="checkbox"] {
      background: transparent;
      border-radius: 0;
    }
  }

  input[type="checkbox"] {
    display: none;
  }
  label {
    cursor: pointer;
    font-weight: normal;
    color: @color-font;
    margin-top: 5px;
  }
  input[type="checkbox"] + label:before {
    border: 1px solid @color-font;
    content: "\e013";
    display: inline-block;
    font-family: "Glyphicons Halflings", sans-serif;
    font-size: 0.6rem;
    color: transparent;
    height: 10px;
    margin: 3px 1rem 0 0;
    padding: 0;
    vertical-align: top;
    width: 10px;
    line-height: 8px;
    text-align: center;
    transition: @transition-standard;
  }
  input[type="checkbox"]:checked + label:before {
    color: @color-font;
  }

  .ws-link, .ws-link[type="submit"] {
    background: @color-bg-button;
    float: none;
    color: @color-font;
    display: inline-block;
    margin-top: 12px;
    text-transform: uppercase;
    font-size: 1rem;
    padding: 6px;
    letter-spacing: 0.32rem;
    border: 0 none;
    font-weight: bold;
    line-height: inherit;

    &[type="submit"] {
      display: block;
    }
  }

  a.ws-link[type="submit"] {
    margin-top: 20px;
  }
}

.login-layer-wrapper {
  position: absolute;
  top: 60px;
  width: 390px;
  right: @grid-gutter-width / 2;
  z-index: 1000;
  height: 0;
  top: -10px;

  .login-layer-scroll {
    overflow: auto;
  }

  .login-layer-border-left {
    position: absolute;
    content: '';
    width: 1px;
    border-left: 1px solid @color-bg-button;
    height: ~"calc(100% + 80px)";
    top: 0;
    left: 0;
    z-index: 1;
  }

  .login-layer-border-right {
    position: absolute;
    content: '';
    width: 1px;
    border-left: 1px solid @color-bg-button;
    height: ~"calc(100% + 15px)";
    top: 0;
    right: 0;
    z-index: 1;
  }

  &.open {
    height: 800px;
    opacity: 1;
  }

  transition: @transition-standard;
  opacity: 0;

  &.login-error {
    .login-failure {
      display: block;
    }
  }

  .login-background {
    position: absolute;
    width: ~"calc(100% + 200px)";
    height: ~"calc(100%)";
    left: -100px;
    //top: -100px;
    background: transparent; /* For browsers that do not support gradients */
    background: -webkit-radial-gradient(@color-bg-gradient-end, transparent); /* Safari 5.1 to 6.0 */
    background: -o-radial-gradient(@color-bg-gradient-end, transparent); /* For Opera 11.6 to 12.0 */
    background: -moz-radial-gradient(@color-bg-gradient-end, transparent); /* For Firefox 3.6 to 15 */
    background: radial-gradient(closest-side, @color-bg-gradient-end, transparent); /* Standard syntax */
    z-index: -200;
    overflow: visible;
    display: none;
  }

  &.login-layer-user {
    .login-background {
      height: ~"calc(40%)";
    }
  }

  &.open {
    .login-background {
      //display: block; // old version
    }
    -webkit-filter: drop-shadow(0 0 15px rgba(0,0,0,0.3));
    filter: drop-shadow(0 0 15px rgba(0,0,0,0.3));
  }

  .login-layer-backgroundfix {
    overflow: hidden;
    height: 100%;
  }

  .login-layer {
    background-color: @color-bg-panel;
    max-width: 390px;
    padding: 16px 35px 0;
    border-bottom: 7px solid @color-bg-button;
    position: relative;

    &:before {
      content: '';
      width: 0;
      height: 0;
      line-height: 0;
      border-right: 470px solid transparent;
      border-top: 80px solid @color-bg-button;
      left: 0;
      top: ~"calc(100% + 7px)";
      position: absolute;
    }

    &:after {
      content: '';
      width: 0;
      height: 0;
      line-height: 0;
      border-right: 470px solid transparent;
      border-top: 80px solid @color-bg-panel;
      left: 0;
      top: 100%;
      position: absolute;
    }

    .login-layer-section {
      margin-bottom: 48px;

      &:last-child {
        margin-bottom: 32px;
      }

      h2 {
        text-transform: uppercase;
        font-size: 1.95rem;
        letter-spacing: 0.36rem;
        margin-bottom: 33px;
        line-height: 2.5rem;
      }

      ::-webkit-input-placeholder {
        color: @color-font !important;
      }

      ::-moz-placeholder {
        color: @color-font !important;
      }

      :-ms-input-placeholder {
        color: @color-font !important;
      }

      :-moz-placeholder {
        color: @color-font !important;
      }

      .login-input-helper;

      .ws-link.button-close-to-headline {
        margin-top: -14px;
      }

      label {
        font-weight: normal;
      }

      p {
        font-size: 1.1rem;
        letter-spacing: 0.07rem;
        line-height: 1.6rem;
        margin-top: -7px;
        margin-bottom: 20px;
      }
    }
  }
}
.login-panel-open + .login-layer-wrapper {
  opacity: 1;
}

.mobile-login-layer {
  &.login-error {
    .login-failure {
      display: block;
    }
  }

  .mobile-login-layer-inner {
    h3 {
      text-transform: uppercase;
      font-size: 2rem;
    }
    input {
      margin-bottom: 6px;
    }
    label {
      color: @color-font;
    }

    .login-input-helper;
  }
}

.login-failure {
  top: 61px;
  position: absolute;
  font-size: 1.1rem;
  letter-spacing: 0.07rem;
  line-height: 1.2rem;
  max-width: 390px;
  margin-right: 35px;
  padding: 1px 10px;
  display: none;
}


