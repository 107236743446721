.contact-partners {
  background: @color-bg-panel-open;
  color: @color-font-alt;
  padding: 28px 0;

  &.contactpage {
    padding: 49px 0 31px;
  }

  .contacts-header {
    margin-bottom: 6px;
    .contacts-headline {
      text-transform: uppercase;
      font-size: 2.1rem;
      font-weight: bold;
      letter-spacing: 0.2rem;
      margin-bottom: 15px;

      &.font-normal {
        font-weight: normal;
      }

      .letter {
        text-transform: none;
        letter-spacing: 0.4rem;
        font-size: 1.3rem;
        margin-right: -3px;
      }
    }

    p {
      margin: 0;
      font-size: 1.18rem;
      line-height: 1.6rem;
      letter-spacing: 0.045rem;
    }
  }

  .person {
    padding-bottom: 24px;
    img {
      max-height: 218px;
    }

    h4 {
      font-size: 1.7em;
      text-transform: uppercase;
      letter-spacing: 0.14rem;
      margin-bottom: 6px;
    }
    p {
      margin: 0;
      font-size: 1.18rem;
      line-height: 1.5rem;
    }
    a {
      color: @color-font-alt;
      .transition;

      &:hover {
        color: @color-bg-panel;
      }
    }
  }

}