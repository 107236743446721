// General settings
@color-bg-panel: rgb(0,114,188);
@color-bg-panel-open: rgb(255,255,255);
@color-bg-stories-slider: rgb(239,239,239);
@color-bg-success-slider: rgb(235,235,235);
@color-bg-quote-box: rgb(0,64,113);
@color-bg-footer: rgb(0,0,0);
@color-bg-banner: @color-bg-panel;
@color-bg-modal: @color-bg-panel;
@color-bg-mobile-menu-corner: rgb(0,82,129);
@color-bg-map: #002A51;
@transition-standard: all 0.4s;
@color-font-error: lighten(#ff0000, 25%);
@color-bg-mobile-navigation: rgb(0,124,192);
@color-bg-gradient-start: rgb(39,114,187);
@color-bg-gradient-end: rgb(27,54,101);

// Header gradient
@color-header-gradient-start: rgb(0,113,177);
@color-header-gradient-end: rgb(0,52,102);

// Font related settings
@font-family: "Noto Sans", sans-serif;
@font-family-headline: "Manus","Noto Sans",sans-serif;
@base-font-size: 13px;
@color-font: @color-bg-panel-open;
@color-font-alt: @color-bg-footer;
@color-font-headline: @color-font;
@color-font-headline-pre: @color-bg-quote-box;
@color-font-headline-sub-alt: @color-bg-panel;
@color-font-headline-alt: @color-font-headline-pre;
@color-font-navlink-active: rgb(156,213,255);
@color-font-adbanner-desc: rgb(192,192,192);
@color-font-radiotab-active: @color-bg-quote-box;
@color-font-input-placeholder: rgb(60,60,60);
@color-font-input-placeholder-light: rgb(255,255,255);
@color-font-number-slider-bg: rgb(243,243,243);

// Input settings
@color-bg-button: @color-bg-quote-box;
@color-font-button: @color-font;
@color-button-border-hover: @color-bg-panel-open;
@button-border-width: 1px;
@color-bg-input: rgb(239,239,239);
@color-bg-input-dark: transparent;
@color-font-input: @color-font;
@color-font-input-alt: @color-bg-footer;
@color-border-input: @color-bg-panel-open;
@color-border-input-alt: @color-bg-quote-box;
@color-bg-radiotab-active: rgb(169,221,254);
@color-font-radiotab-active: @color-bg-quote-box;
@color-border-multiple-select-selection: @color-bg-panel;
@color-checkbox-inner: @color-bg-gradient-start;
@color-radiobox-inner: @color-bg-gradient-start;

// Image slider settings
@color-image-border-active: @color-bg-quote-box;
@color-text-zoom: @color-font-adbanner-desc;

// Details
@color-icon: @color-bg-quote-box;
@color-bg-modal: rgb(39,114,187);
@color-border-modal: @color-bg-panel-open;
@color-shadow-modal: rgba(149,149,149,0.6);
@color-shadow-mobile-navigation: rgba(61,61,61,1);
@color-horizontal-line: @color-bg-quote-box;
@color-vertical-line: @color-horizontal-line;
@color-slider-indicator: @color-bg-quote-box;
@color-slider-indicator-active: @color-bg-panel;
@color-loader-dark-bg: @color-bg-panel-open;
@color-loader-light-bg: @color-font-input-placeholder;
@color-bg-carousel-indicator: @color-bg-panel;
@color-bg-carousel-indicator-hover: @color-font-navlink-active;
@color-bg-price-sticker: @color-font;
@color-font-price-sticker: @color-bg-quote-box;
@color-bg-fullscreen-slider: @color-header-gradient-end;
