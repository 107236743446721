.ua-firefox {
  .form-dark-bg {
    input {
      padding: 5.5px 11px;
    }
  }
}

.searchbox-wrapper button {
  float: left; // doesnt work
}

.ua-safari {
  input[type="text"], input[type="password"] {
    line-height: 1.6rem;
    padding-bottom: 0;
    padding-top: 1px;
  }
  .form-dark-bg input[type="text"], input[type="password"] {
    line-height: 2rem;
    padding-bottom: 2px;
  }

  .ws-link, .ws-link-inner {
    &:before, &:after {
      display: none;
    }
  }

  .startpage-layout {
    .search {
      .search-inner input {
        border-radius: 0;
      }
    }
  }

  .login-layer-wrapper {
    top: 20px;
  }
}

.ua-ie-11, .ua-edge {
  .search-button.ie-fix {
    background-size: 80px !important;
    background-position: top 2px left 6px !important;
  }
  .success-slider .carousel .carousel-control.right {
    background-size: 116px;
    background-position: top 54% left;
  }
  .contact-map .map-google {
    &:hover, &:focus {
      transform: scale(1);
    }
  }
}

.ua-firefox {
  input[type="text"], input[type="email"], input[type="password"], textarea, textarea.form-control {
    padding: 3.5px 11px;
  }
  .form-dark-bg {
    input[type="text"], input[type="email"], input[type="password"], textarea, textarea.form-control {
      padding: 5.5px 11px;
    }
  }
}