@media print {
  header, footer {
    display: none;
  }
  body {
    padding-top: 0;
  }
  input {
    outline: 1px solid @color-border-input-alt !important;
  }
  a[href]:after {
    content: "" !important;
  }
  .detail-content {
    img {
      float: left !important;
      max-width: 45% !important;
      margin-right: 30px;
      margin-bottom: 30px;
    }
  }
  .disturber {
    display: none;
  }
  color: @color-font-alt !important;
}