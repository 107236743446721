.ad-box .container {
  position: relative;
  color: @color-font-adbanner-desc;

  .column {
    position: fixed;
    right: 0;
    width: 210px;
    top: 232px;
    transition: top 0.4s;
    opacity: 0;
    z-index: 20;
    &.collapsed {
      top: 83px;
    }

    img {
      margin-bottom: 10px;
    }
  }

  a {
    color: @color-font-adbanner-desc;
  }

  .text {
    letter-spacing: 0.23em;
    font-weight: 700;
    margin-bottom: 12px;
    font-size: 0.8em;
    line-height: 1.8px;
  }
  .link a {
    transition: @transition-standard;
    &:hover, &:focus {
      color: #939393;
    }
  }
  .closebutton {
    position: absolute;
    right: 0px;
    top: -2px;
    width: 32px;
    height: 32px;
    opacity: 0.3;
    background-color: #fff;
    border-radius: 15px;
    padding-top: 4px;
  }
  .closebutton:hover {
    opacity: 1;
  }
  .closebutton:before, .closebutton:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 25px;
    width: 2px;
    background-color: #333;
  }
  .closebutton:before {
    transform: rotate(45deg);
  }
  .closebutton:after {
    transform: rotate(-45deg);
  }
}