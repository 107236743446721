.ws-modal {
  &.open {
    opacity: 1;
    visibility: visible;
    .ws-modal-inner {
      padding: 28px 60px;
    }
  }
  opacity: 0;
  visibility: hidden;
  position: fixed;
  background: rgba(255,255,255,.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 199; //navbar is 200
  padding-top: 15%;
  transition: @transition-standard;
  &.dark-bg {
    background: rgba(0,0,0,0.1);
    .ws-modal-inner {
      -webkit-box-shadow: 0px 0px 22px 8px darken(@color-shadow-modal, 40);
      -moz-box-shadow: 0px 0px 22px 8px darken(@color-shadow-modal, 40);
      box-shadow: 0px 0px 22px 8px darken(@color-shadow-modal, 40);
    }
  }
  .ws-modal-inner {
    will-change: padding;
    padding: 0;
    overflow: hidden;
    width: 42%;
    margin-left: 29%;
    background: @color-bg-modal;
    outline: 1px solid @color-border-modal;
    -webkit-box-shadow: 0px 0px 22px 8px @color-shadow-modal;
    -moz-box-shadow: 0px 0px 22px 8px @color-shadow-modal;
    box-shadow: 0px 0px 22px 8px @color-shadow-modal;
    position: relative;
    transition: @transition-standard;

    .step-tab {
      position: absolute;
      opacity: 0;
      width: 100%;
      display: block;
      transition: opacity .4s;
      left: 0;
      top: 28px;
      z-index: 0;
      &.active {
        top: 0;
        position: relative;
        opacity: 1;
        z-index: 1;
      }
    }

    &:after {
      content: "";
      clear: both;
    }

    img {
      width: 45px;
    }
    h3 {
      font-size: 2rem;
      text-transform: uppercase;
      letter-spacing: 0.32rem;
      margin-top: 21px;
      margin-bottom: 23px;
    }
    p {
      font-size: 1.15rem;
      letter-spacing: 0.06rem;
      line-height: 1.6rem;
      margin-bottom: 16px;
    }
    a {
      margin-top: 7px;
      width: 40%;
    }
    textarea, input[type="email"], input[type="text"], input[type="tel"] {
      width: 81%;
    }
    textarea {
      line-height: 1.3rem;
      margin-top: 5px;
    }
  }
}