.startpage-layout {
  background: url("../Img/map.png") center top no-repeat @color-bg-map;
  background-size: ~"auto calc(100% - 165px)";
}

.startpage header {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  position: absolute;

  .meta-nav {
    margin-top: 12px;
  }
}

.startpage {
  .container.main {
    padding-top: 87px;
    padding-bottom: 64px;

    h2 {
      font-size: 2.5em;
      padding-left: 16px;
    }
    h1.title {
      padding-left: 14px;
      padding-top: 3px;
      margin-bottom: 18px;
    }

    p {
      margin-left: 16%;
      margin-bottom: 60px;
      width: 67%;
    }
  }

  [type="submit"], [type="text"],
  [type="reset"], textarea {
    background-color: transparent;
    border: 0;
    &:focus {
      outline: none;
    }
    &:hover, &:focus {
      &:before, &:after {
        display: none;
      }
    }
  }

  [type="text"], textarea {
    width: 100%;
    border: 1px solid @color-border-input;
  }

  .arrow-down {
    padding-top: 90px;
    img {
      height: 73px;
      cursor: pointer;
      transition: @transition-standard;
      &:hover, &:focus {
        transform: scale(1.1);
      }
    }

  }
}

.zindex-fix {
  z-index: 100;
}